import vanillaAxios, { AxiosError } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { BASE_URL } from '@/constants/env';
import { API_AUTH_LOGIN, API_AUTH_USER, API_AUTH_USER_QRCODE, API_AUTH_USER_VERIFY_OTP } from "@/constants/api";
import { computed, reactive, toRefs } from "vue";
import { useAxios } from "@/composables/useAxios";
import { Storage } from "@capacitor/storage";
import { ROUTE_SIGN_IN } from "@/constants/routes";
import { ROLES_ADMIN } from "@/constants/roles";

export interface AuthData {
  user: any;
  role: string | null;
  token: string | null;
}

const authData = reactive<AuthData>({
  user: null,
  role: null,
  token: null,
});

const axiosInstance = applyCaseMiddleware(vanillaAxios.create({ baseURL: BASE_URL }));
const {
  setToken: addTokenToAxiosInstance,
  removeToken: removeTokenFromAxiosInstance,
  axios,
} = useAxios();

export function useAuthentication() {
  const axios2faInstance = applyCaseMiddleware(vanillaAxios.create({
    baseURL: BASE_URL,
    headers: { 'Authorization': `Bearer ${ authData.token }` }
  }));

  async function storeUserCredentials(token: string, user: any, role: string) {
    authData.token = token;
    authData.role = role;
    authData.user = user;

    await Storage.set({ key: 'token', value: token });
  }

  function signIn(username: string, password: string) {
    return axiosInstance.post(API_AUTH_LOGIN, { username, password })
      .then(({ data }: any) => {
        authData.token = data.token;
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          throw new AxiosError(error);
        }
        // Todo: log error
        console.log('log error');
      });
  }

  function signOut() {
    removeTokenFromAxiosInstance();
    purgeUserCredentials();
    window.location.href = ROUTE_SIGN_IN;
  }

  async function restoreUser(): Promise<any> {
    const { value: token } = await Storage.get({ key: 'token' });
    if (token) {
      addTokenToAxiosInstance(token);
      return axios.value.get(API_AUTH_USER)
        .then(({ data }) => {
          storeUserCredentials(token, data.user, data.role);
        })
        .catch(() => {
          return Promise.resolve();
        })
        .finally(() => {
          return Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  async function purgeUserCredentials() {
    authData.token = null;
    authData.role = null;
    authData.user = null;

    await Storage.remove({ key: 'token' });
  }

  function getQrCode() {
    if (authData.token === null) signOut();

    return axios2faInstance.get(API_AUTH_USER_QRCODE)
      .then(({ data }) => {
        return data;
      });
  }

  function verifyOTP(oneTimePassword: string) {
    return axios2faInstance.post(API_AUTH_USER_VERIFY_OTP, { oneTimePassword })
      .then(async ({ data }: any) => {
        if (!data.role) signOut();
        storeUserCredentials(data.token, data.user, data.role)
        addTokenToAxiosInstance(data.token);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          throw new AxiosError(error);
        }
        // Todo: log error
        console.log('log error');
      });
  }

  function generatePassword() {
    const characters = 'a-z,A-Z,0-9,#';
    const size = 32;
    const charactersArray = characters.split(',');
    let CharacterSet = '';
    let password = '';

    if( charactersArray.indexOf('a-z') >= 0) {
      CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
    }
    if( charactersArray.indexOf('A-Z') >= 0) {
      CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if( charactersArray.indexOf('0-9') >= 0) {
      CharacterSet += '0123456789';
    }
    if( charactersArray.indexOf('#') >= 0) {
      CharacterSet += '![]{}()%&*$#^<>~@|';
    }

    for(let i=0; i < size; i++) {
      password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    }
    return password;
  }

  const isAuthenticated = computed((): boolean => !!authData.token && !!authData.user);
  const isAdmin = computed((): boolean => authData.role === ROLES_ADMIN);

  return {
    signIn,
    signOut,
    restoreUser,
    getQrCode,
    verifyOTP,
    generatePassword,
    isAuthenticated,
    isAdmin,
    ...toRefs(authData)
  };
}