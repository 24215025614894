
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import TempMenu from "@/components/TempMenu.vue";

export default defineComponent({
  name: 'App',
  components: {
    TempMenu,
    IonApp,
    IonRouterOutlet,
  }
});
