import { RouteRecordRaw } from 'vue-router';
import {
  ROUTE_INVESTOR_INFO,
} from '@/constants/routes';

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_INVESTOR_INFO,
    component: () => import ('@/views/investors/Info.vue'),
    meta: {
      investor: true,
    },
  },
];

export default routes;
