import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, { onClick: _ctx.signOut }, {
            default: _withCtx(() => [
              _createTextVNode("Sign out")
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (page) => {
            return (_openBlock(), _createBlock(_component_ion_button, {
              key: page.url,
              "router-link": page.url,
              "router-direction": "root"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(page.title), 1)
              ]),
              _: 2
            }, 1032, ["router-link"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}