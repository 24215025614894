import { RouteRecordRaw } from 'vue-router';
import { ROUTE_SIGN_IN, ROUTE_SIGN_IN_2FA } from '@/constants/routes';

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_SIGN_IN,
    component: () => import ('../../views/authentication/SignIn.vue'),
    meta: {
      common: true,
    },
  },
  {
    path: ROUTE_SIGN_IN_2FA,
    component: () => import ('../../views/authentication/SignIn2FA.vue'),
    meta: {
      common: true,
    },
  },
];

export default routes;
