
import { IonHeader, IonToolbar, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

import {
  ROUTE_ADMIN_INVESTORS_COMPANIES,
  ROUTE_ADMIN_INVESTORS_INDIVIDUALS,
  ROUTE_HOME_INVESTORS
} from "@/constants/routes";
import { useAuthentication } from "@/composables/useAuthentication";

export default defineComponent({
  name: 'TempMenu',
  components: {
    IonHeader,
    IonToolbar,
    IonButton,
  },
  setup() {
    const { signOut } = useAuthentication();
    return {
      signOut
    }
  },
  data() {
    return {
      appPages: [
        {
          title: 'investor info',
          url: ROUTE_HOME_INVESTORS,
        },
        {
          title: 'customers',
          url: ROUTE_ADMIN_INVESTORS_INDIVIDUALS,
        },
        {
          title: 'companies',
          url: ROUTE_ADMIN_INVESTORS_COMPANIES,
        },
      ],
    }
  },
});
