import { RouteRecordRaw } from 'vue-router';
import {
  ROUTE_ADMIN_INVESTORS_COMPANIES,
  ROUTE_ADMIN_INVESTORS_INDIVIDUALS

} from '@/constants/routes';

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_ADMIN_INVESTORS_INDIVIDUALS,
    component: () => import ('../../views/admin/investors/individuals/index.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: `${ ROUTE_ADMIN_INVESTORS_INDIVIDUALS }/:id`,
    component: () => import ('../../views/admin/investors/individuals/show.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: ROUTE_ADMIN_INVESTORS_COMPANIES,
    component: () => import ('../../views/admin/investors/companies/index.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: `${ ROUTE_ADMIN_INVESTORS_COMPANIES }/:id`,
    component: () => import ('../../views/admin/investors/companies/show.vue'),
    meta: {
      admin: true,
    }
  },
];

export default routes;
