// Auth
export const ROUTE_SIGN_IN = '/login';
export const ROUTE_SIGN_IN_2FA = '/verification';

// Investors
export const ROUTE_INVESTOR_INFO = '/investor/info'

// Admin
export const ROUTE_ADMIN_INVESTORS_INDIVIDUALS = '/admin/investors/individuals';
export const ROUTE_ADMIN_INVESTORS_COMPANIES = '/admin/investors/companies';

// Home
export const ROUTE_HOME_INVESTORS = ROUTE_INVESTOR_INFO;
export const ROUTE_HOME_ADMIN = ROUTE_ADMIN_INVESTORS_INDIVIDUALS;

// Common
export const ROUTE_PAGE_NOT_FOUND = '/page-not-found';
export const ROUTE_PAGE_FORBIDDEN = '/forbidden';